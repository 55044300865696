import React from 'react';
import PropTypes from 'prop-types';
import './TooltipMarkerData.scss';

const TooltipMarkerData = ({ name, calculatedPlacedHivesAmount, hivesAmount, bhomes, plannedBhomesAmount, note }) => (
    <div className="tooltip-marker-info">
        <h3>Drop {name}</h3>
        <p>
            <span>Hives:</span>{' '}
            {calculatedPlacedHivesAmount
                ? `${calculatedPlacedHivesAmount}/${hivesAmount} placed`
                : `${hivesAmount} planned`}
        </p>
        <p>
            <span>Beehomes:</span>{' '}
            {bhomes?.length ? `${bhomes?.length}/${plannedBhomesAmount} placed` : `${plannedBhomesAmount} planned`}
        </p>
        {note && <p className="tooltip-drop-info-note">&quot;{note}&quot;</p>}
    </div>
);

TooltipMarkerData.propTypes = {
    name: PropTypes.string.isRequired,
    calculatedPlacedHivesAmount: PropTypes.number,
    hivesAmount: PropTypes.number.isRequired,
    bhomes: PropTypes.arrayOf(PropTypes.number),
    plannedBhomesAmount: PropTypes.number.isRequired,
    note: PropTypes.string,
};

export default TooltipMarkerData;

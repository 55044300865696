export const getGeneralSectionFields = ({ ranch, totalArea, units }) => {
    const fields = [{ name: 'Total area', value: `${totalArea} ${units}` }];

    if (ranch.crop) {
        fields.push({
            name: 'Crop',
            value: ranch.crop.split('_').join(' '),
        });
    }

    if (ranch.note) {
        fields.push({ name: 'Notes', value: ranch.note });
    }

    if (ranch?.trees?.length) {
        fields.push({
            name: 'Tree Varieties',
            value: ranch.trees.map(item => `${item.name} ${item.percent}%`).join(', '),
        });
    }

    return fields;
};

export const getPollinationDetailFields = ({ ranch, ratioHeader, orderedHives, placedHives }) => {
    const fields = [];

    if (ranch.ratio) {
        fields.push({
            name: ratioHeader,
            value: ranch.ratio,
        });
    }

    fields.push(
        { name: 'Required number of hives', value: ranch.requiredHivesAmount },
        { name: 'Ordered hives', value: orderedHives },
        { name: 'Placed hives', value: placedHives },
        { name: 'Total hive drops', value: ranch.locations.length }
    );

    return fields;
};

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector, shallowEqual } from 'react-redux';
import { getBeekeeperByIds } from 'components/views/BrokerView/selectors';
import MapInfo from 'components/reusables/Map/components/MapInfo';
import MapV2 from 'components/reusables/Map/components/MapV2';
import LocationMarker from 'components/reusables/Map/components/LocationMarkerv2';
import PinMarker from 'components/reusables/Map/components/PinMarkerv2';
import { getBeekeeperColor } from 'utils/dataModelGetters';
import useFormMethods from './hooks/useFormMethods';
import PerformanceWidget from './PerformanceWidget';
import Toolbar from './Toolbar';
import useMap from './hooks/useMap';
import { MAP_MARKER_TYPE } from './hooks/useMarkerMode';
import './Map.scss';

const MapView = ({ isToolbarEnabled }) => {
    const beekeeperByIds = useSelector(getBeekeeperByIds, shallowEqual);
    const {
        addPolygon,
        addAccessRoad,
        createLocation,
        removePolygon,
        removeLocation,
        updateLocation,
        updatePolygon,
        form,
        locations,
        pins,
        createPin,
        removePin,
        updatePin,
        updateUnverifiedPlacements,
    } = useFormMethods();

    const { mode, onModeChange, map, isDraggingRef, onGoogleApiLoaded, handleEditMarker } = useMap({
        addPolygon,
        addAccessRoad,
        removePolygon,
        updatePolygon,
        isToolbarEnabled,
        createLocation,
        removeLocation,
        updateLocation,
        form,
        pins,
        createPin,
        removePin,
        updatePin,
    });

    return (
        <section className={cn('map-view', mode)}>
            <MapV2
                extraOptions={
                    isToolbarEnabled && (
                        <>
                            <Toolbar
                                mode={mode}
                                setMode={onModeChange}
                                map={map}
                                updateUnverifiedPlacements={updateUnverifiedPlacements}
                                control={form.control}
                            />
                            <MapInfo />
                            <PerformanceWidget />
                        </>
                    )
                }
                onGoogleApiLoaded={onGoogleApiLoaded}
            >
                {locations?.map((location, index) => {
                    const { beekeeperColor, ...locationWithoutBeekeeperColor } = location;
                    return (
                        <LocationMarker
                            lat={location.lat}
                            lng={location.lng}
                            // resets indexes after removal, if wierd behaviour would exists - wrap LocationMarker in FormField and pass control inside
                            key={`${location._id}-${index}`}
                            draggable
                            onDrag={() => {
                                isDraggingRef.current = true;
                            }}
                            onDragEnd={(_, { latLng: { lat, lng } }) => {
                                isDraggingRef.current = false;
                                updateLocation(index, { ...locationWithoutBeekeeperColor, lat, lng });
                            }}
                            onClick={handleEditMarker({
                                entity: locationWithoutBeekeeperColor,
                                index,
                                isDraggingRef,
                                mapMarkerType: MAP_MARKER_TYPE.LOCATION,
                            })}
                            beekeeperColor={getBeekeeperColor({ beekeeperByIds, marker: location })}
                            {...location}
                        />
                    );
                })}
                {pins.map((pin, index) => (
                    <PinMarker
                        key={`${pin._id}-${index}`}
                        lat={pin.lat}
                        lng={pin.lng}
                        draggable
                        onDrag={() => {
                            isDraggingRef.current = true;
                        }}
                        onDragEnd={(_, { latLng: { lat, lng } }) => {
                            isDraggingRef.current = false;
                            updatePin(index, { ...pin, lat, lng });
                        }}
                        onClick={handleEditMarker({
                            entity: pin,
                            index,
                            isDraggingRef,
                            mapMarkerType: MAP_MARKER_TYPE.PIN,
                        })}
                        {...pin}
                    />
                ))}
            </MapV2>
        </section>
    );
};

MapView.propTypes = {
    isToolbarEnabled: PropTypes.bool,
};

export default memo(MapView);

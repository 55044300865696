import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchUpdateRanch } from 'components/views/BrokerView/actions';
import { getDirtyFormFields } from '@beewise/react-utils';

// removes temp _id, created but react-hook-form
const prepareArrayFields = fields => fields.map(({ _id, ...field }) => field);

const useSubmitForm = ({ form }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const {
        handleSubmit,
        formState: { defaultValues },
    } = form;

    const onSubmit = handleSubmit(data => {
        const dirtyFields = getDirtyFormFields({
            initialFields: defaultValues,
            newFields: {
                ...data,
                locations: prepareArrayFields(data.locations),
                orders: prepareArrayFields(data.orders),
                pins: prepareArrayFields(data.pins),
            },
            fieldsToStringify: [
                'polygons',
                'loadingZoneCoordinates',
                'accessRoadCoordinates',
                'bhomes',
                'standardHives',
            ],
        });

        const body = {
            ...dirtyFields,
            id,
        };
        dispatch(
            fetchUpdateRanch({
                body,
                resolver: () => navigate('/'),
            })
        );
    });

    return { onSubmit };
};

export default useSubmitForm;

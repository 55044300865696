import constants from 'appConstants';
import { faNote, faDungeon, faRoadBarrier, faQuestion, faForklift } from '@fortawesome/pro-light-svg-icons';

export const PIN_CONFIG = {
    [constants.PIN_TYPES.PIN]: {
        icon: faNote,
        entity: 'Note',
        tooltip: true,
    },
    [constants.PIN_TYPES.GATE]: {
        icon: faDungeon,
        entity: 'Gate',
        tooltip: true,
    },
    [constants.PIN_TYPES.ROAD_BLOCK]: {
        icon: faRoadBarrier,
        entity: 'Road block',
        tooltip: true,
    },
    [constants.PIN_TYPES.LABEL]: {
        icon: 'label',
        entity: 'Label',
        tooltip: false,
    },
    [constants.PIN_TYPES.LOADING_ZONE]: {
        icon: faForklift,
        entity: 'Loading Zone',
        tooltip: true,
    },
};

export const getIcon = type => PIN_CONFIG[type]?.icon || faQuestion;
export const getPinEntity = type => PIN_CONFIG[type]?.entity || 'Unknown';
export const getPinTooltip = type => PIN_CONFIG[type]?.tooltip || false;

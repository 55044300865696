import constants from 'appConstants';

const { MARKER_HIVE_TYPES } = constants;

const getCombinedHiveTypes = ({ bhomesHivesAmount, standardHivesAmount }) => {
    if (bhomesHivesAmount && standardHivesAmount) {
        return MARKER_HIVE_TYPES.COMBINED;
    } else if (bhomesHivesAmount) {
        return MARKER_HIVE_TYPES.BHOME;
    }
    return MARKER_HIVE_TYPES.STANDARD_HIVES;
};

export const getMarkerType = ({ placed, planned }) => {
    if (planned.hivesAmount > placed.hivesAmount) {
        return getCombinedHiveTypes({
            bhomesHivesAmount: placed.bhomesHivesAmount || planned.bhomesHivesAmount,
            standardHivesAmount: placed.standardHivesAmount || planned.standardHivesAmount,
        });
    }
    return getCombinedHiveTypes(placed);
};

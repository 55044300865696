import React from 'react';
import { useSelector } from 'react-redux';
import { shallowEqual } from '@beewise/react-utils';
import { getSelectedRanch } from 'components/views/BrokerView/selectors';
import useRanchSelection from 'components/views/BrokerView/views/Dashboard/hooks/useRanchSelection';
import RanchHeader from './RanchHeader';
import RanchDetails from './RanchDetails';
import MapContainer from './MapContainer';
import './RanchInfo.scss';

const RanchInfo = () => {
    const currentRanch = useSelector(getSelectedRanch, shallowEqual);

    const { handleSelectRanch } = useRanchSelection();

    return (
        <div className="ranch-container">
            <RanchHeader currentRanch={currentRanch} />
            <MapContainer currentRanch={currentRanch} handleSelectRanch={handleSelectRanch} />
            <RanchDetails currentRanch={currentRanch} />
        </div>
    );
};

export default RanchInfo;

import { BEES_SAVED_PER_DAY, MAX_DAYS_TO_COUNT } from 'components/constants';

export const calculateSavedBees = (startDate, bhomesAmount) => {
    const currentDate = new Date();
    const pollinationStartDate = new Date(startDate);

    const daysOfPollination = Math.min(
        Math.floor((currentDate - pollinationStartDate) / (24 * 60 * 60 * 1000)),
        MAX_DAYS_TO_COUNT
    );

    const savedBees = bhomesAmount * daysOfPollination * BEES_SAVED_PER_DAY;

    return savedBees;
};

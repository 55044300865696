import { createAsyncAction } from '@beewise/utils';

export const FETCH_RANCHES = createAsyncAction('FETCH_RANCHES');
export const FETCH_USER = createAsyncAction('FETCH_USER');
export const FETCH_FRAMES_PER_HIVE = createAsyncAction('FETCH_FRAMES_PER_HIVE');
export const FETCH_RANCH_BY_ID = createAsyncAction('FETCH_RANCH_BY_ID');
export const FETCH_RANCH_APPROVAL = createAsyncAction('FETCH_RANCH_APPROVAL');
export const FETCH_BEE_ACTIVITIES_OVER_TIME = createAsyncAction('FETCH_BEE_ACTIVITIES_OVER_TIME');
export const SET_RANCH_APPROVAL = createAsyncAction('SET_RANCH_APPROVAL');
export const FETCH_HIVE_DEVELOPMENT = createAsyncAction('FETCH_HIVE_DEVELOPMENT');
export const SET_SELECTED_RANCH_ID = 'SET_SELECTED_RANCH_ID';

import React from 'react';
import constants from 'appConstants';
import Tooltip from '@beewise/tooltip';
import PropTypes from 'prop-types';
import StandardPin from './StandardPin';
import LabelPin from './LabelPin';
import { getPinTooltip } from './utils';
import './PinMarker.scss';

const getPinComponent = type => {
    if (type === constants.PIN_TYPES.LABEL) {
        return LabelPin;
    }
    return StandardPin;
};

const NoteTooltip = ({ note }) =>
    note && (
        <div className="bhome-marker-tooltip">
            <p>{note}</p>
        </div>
    );

const PinMarker = ({ onClick, ...marker }) => {
    const { type, note } = marker;
    const PinComponent = getPinComponent(type);
    const isWithTooltip = getPinTooltip(type);

    return (
        <Tooltip text={<NoteTooltip note={note} />} position="top center" disabled={!note || !isWithTooltip}>
            <div onMouseUp={onClick} role="presentation" className="pin">
                <PinComponent type={type} note={note} />
            </div>
        </Tooltip>
    );
};

PinMarker.propTypes = {
    onClick: PropTypes.func,
    type: PropTypes.string,
    note: PropTypes.string,
};

NoteTooltip.propTypes = {
    note: PropTypes.string,
};

export default PinMarker;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { COUNTRY_IL, COUNTRY_US } from 'utils/isIsraeliRanch';
import { fetchCompaniesWithUsers, fetchRanches, fetchUsers } from '../../actions';
import RanchesBlock from './components/RanchesBlock';
import RanchInfo from './components/RanchInfo';
import { getFilteredRanches, getRanches, getSelectedBrokerFilter } from '../../selectors';
import { MapProvider } from './context/MapContext';
import { ListProvider } from './context/ListContext';

const Dashboard = () => {
    const dispatch = useDispatch();
    const selectedBrokerFilter = useSelector(getSelectedBrokerFilter);
    const ranches = useSelector(getRanches, arrayOfObjectsShallowEqual);
    const filteredRanches = useSelector(getFilteredRanches, arrayOfObjectsShallowEqual);

    useEffect(() => {
        dispatch(fetchCompaniesWithUsers());
        dispatch(fetchUsers({ type: 'broker' }));
        if ([COUNTRY_US, COUNTRY_IL].includes(selectedBrokerFilter)) {
            dispatch(fetchRanches({ country: selectedBrokerFilter }));
        } else {
            dispatch(fetchRanches({ brokerId: selectedBrokerFilter }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <MapProvider isBrokerView>
            <ListProvider>
                <RanchesBlock ranches={ranches} filteredRanches={filteredRanches} />
                <RanchInfo />
            </ListProvider>
        </MapProvider>
    );
};

export default Dashboard;

/* eslint-disable import/no-unresolved */
import { createAsyncAction } from '@beewise/utils';

export const SET_PASSWORD = createAsyncAction('SET_PASSWORD');

export const setPasswordAndUsername = (password, username = '', confirmationToken, resolver, rejector) => ({
    type: 'FETCH',
    params: {
        type: SET_PASSWORD.default,
        method: 'PUT',
        url: '/users/password',
        body: {
            password,
            confirmationToken,
            username,
        },
        resolver,
        rejector,
    },
});

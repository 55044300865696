import { useMemo, useRef, useEffect } from 'react';
import { isEqual } from 'lodash-es';
import { getVisibleMarkers } from 'components/reusables/Map/components/utils';

const useVisibleMarkers = ({ ranches, mapOptions, isBhomeView }) => {
    const currentRanches = useRef([]);
    const visibleRanches = useMemo(() => {
        const filteredRanches = getVisibleMarkers({ markers: ranches, bounds: mapOptions?.bounds });

        if (isEqual(currentRanches.current, filteredRanches)) {
            return currentRanches.current;
        }
        return filteredRanches;
    }, [ranches, mapOptions?.bounds]);

    const visibleMarkers = useMemo(
        () => (isBhomeView ? visibleRanches.flatMap(ranch => ranch.locations) : visibleRanches),
        [isBhomeView, visibleRanches]
    );

    const pinMarkers = useMemo(
        () => (isBhomeView ? visibleRanches.flatMap(ranch => ranch.pins ?? []) : []),
        [isBhomeView, visibleRanches]
    );

    useEffect(() => {
        currentRanches.current = visibleRanches;
    }, [visibleRanches]);

    return { visibleMarkers, pinMarkers, visibleRanches };
};

export default useVisibleMarkers;

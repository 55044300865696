import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import MapV2 from 'components/reusables/Map/components/MapV2';
import MapInfo from 'components/reusables/Map/components/MapInfo';
import { getFilteredRanches, getBeekeeperByIds } from 'components/views/BrokerView/selectors';
import RanchMarkerV2 from 'components/reusables/Map/components/RanchMarkerV2';
import LocationMarkerv2 from 'components/reusables/Map/components/LocationMarkerv2';
import PinMarker from 'components/reusables/Map/components/PinMarkerv2';
import { getBeekeeperColor } from 'utils/dataModelGetters';
import useMap from '../../../hooks/useMap';

const MarkerComponent = ({ isBhomeView, ...props }) => {
    const MarkerComponent = isBhomeView ? LocationMarkerv2 : RanchMarkerV2;
    return <MarkerComponent {...props} />;
};

MarkerComponent.propTypes = {
    isBhomeView: PropTypes.bool,
};

const MapContainer = ({ currentRanch, handleSelectRanch }) => {
    const filteredRanches = useSelector(getFilteredRanches, arrayOfObjectsShallowEqual);
    const beekeeperByIds = useSelector(getBeekeeperByIds);

    const mapConfig = {
        ranches: filteredRanches,
        selectedRanch: currentRanch,
        handleSelectRanch,
    };
    const { isBhomeView, handleMapChange, visibleMarkers, pinMarkers, handleLoadMaps } = useMap(mapConfig);

    return (
        <MapV2 onGoogleApiLoaded={handleLoadMaps} extraOptions={<MapInfo />} handleMapChange={handleMapChange}>
            {visibleMarkers.map(marker => (
                <MarkerComponent
                    key={marker.id}
                    lat={marker.lat}
                    lng={marker.lng}
                    handleMarkerClick={handleSelectRanch}
                    isBhomeView={isBhomeView}
                    beekeeperColor={getBeekeeperColor({ beekeeperByIds, marker })}
                    {...marker}
                />
            ))}
            {pinMarkers.map(marker => (
                <PinMarker key={marker.id} {...marker} lat={marker.lat} lng={marker.lng} />
            ))}
        </MapV2>
    );
};

export default MapContainer;

MapContainer.propTypes = {
    currentRanch: PropTypes.shape(),
    handleSelectRanch: PropTypes.func,
    isBrokerView: PropTypes.bool,
};

import constants from 'appConstants';
import { DEFAULT_QUALITY_OF_FRAMES } from 'components/constants';
import { convertValues, measureUnits } from './isIsraeliRanch';
import { UI_ENV } from '../config';

const { TOOLBOX_MODE } = constants;

export const isProduction = () => UI_ENV === 'prod';

export const getLocationType = ({ bhomeIds, placedHivesAmount, showPotential }) => {
    if (!bhomeIds?.length) {
        if (!placedHivesAmount) {
            return constants.MARKER_TYPE.EMPTY_LOCATION;
        }
        return constants.MARKER_TYPE.STANDARD_HIVE;
    }

    if (showPotential) {
        return constants.MARKER_TYPE.POLLINATING_BHOME;
    }

    return constants.MARKER_TYPE.NON_POLLINATING_BHOME;
};

// ensure halo is ~316m (default is ~400m)
const defaultHaloDistance = 400;
const distanceCoefficient = 0.79; // 316 m

export const getDistanceRadius = (hivesAmount, ratio, country) =>
    Math.round(Math.sqrt(convertValues(Number(hivesAmount) / ratio, measureUnits[country]?.areaUnit, 'm2')));

export const getCoefficient = distanceRadius => distanceRadius / defaultHaloDistance;

export const getShowDistanceOnHalo = (zoom, coefficient) =>
    (zoom > 15 && coefficient > 0.19) ||
    (zoom > 13 && coefficient >= distanceCoefficient) ||
    (zoom > 14 && coefficient > 0.39);

export const hivesAmountKey = `${UI_ENV}_location_hives_amount`;

export const getPolygonCountry = polygon => {
    const [coords] = polygon.getPath().getArray();
    const lng = coords.lng();
    return lng > 0 ? 'il' : 'us';
};

export const getRequiredNumberOfHives = (ratio, totalArea) => Math.ceil(ratio * totalArea);
export const getRequiredRation = (hivesAmount, totalArea) => Math.ceil(hivesAmount / totalArea);

export const getPolygonArea = (polygon, country) =>
    convertValues(
        window.google.maps.geometry.spherical.computeArea(polygon.getPath()),
        'm2',
        measureUnits[country]?.areaUnit
    );

export const getCursor = toolboxOption => {
    switch (toolboxOption) {
        case TOOLBOX_MODE.MARK_LOADING_ZONE:
            return 'crosshair';
        // FIXME: not working from here, current solution is to set from the scss with !important option (.map-container.mark-access-road-mode)
        // case TOOLBOX_MODE.MARK_ACCESS_ROAD:
        //     return `url(${DoneDrawingIcon}) 10 20, default`;
        case TOOLBOX_MODE.MARK_ACCESS_ROAD:
        case TOOLBOX_MODE.MARK_PINS:
        case TOOLBOX_MODE.MARK_GATES:
        case TOOLBOX_MODE.MARK_ROAD_BLOCKS:
            return 'pointer';
        case TOOLBOX_MODE.PLACE_LOCATION:
        case TOOLBOX_MODE.MOVE:
            return 'grab';
        default:
            return 'default';
    }
};

export const calculateMarkerStyle = ({ marker }) => {
    if (marker.placedHivesAmount) {
        return marker.placedHivesAmount >= marker.hivesAmount ? 'placed-fully' : 'placed-partially';
    }

    return 'placed-empty';
};

export const calculateMarkerLineStyle = ({ framesPerHiveByBhomeId, marker }) => {
    if (framesPerHiveByBhomeId && marker.placedHivesAmount) {
        const markerId = marker.bhomeId;
        const markerData = framesPerHiveByBhomeId[markerId];

        if (markerData) {
            const { average } = markerData;

            if (average > 10) {
                return '#43976A';
            } else if (average > 7) {
                return '#B6CF41';
            } else if (average > 5) {
                return '#FDBA12';
            } else {
                return '#F44336';
            }
        }
    }
};

export const getAverageFramesData = (averageFramesData, marker, selectedRanchQuality) => {
    if (!averageFramesData) {
        return { frameTitle: null, standardFrames: null, actualFrames: null };
    }

    return {
        frameTitle: 'Frames with Bees (average)',
        standardFrames: `Standard: ${selectedRanchQuality || DEFAULT_QUALITY_OF_FRAMES}`,
        actualFrames: `Actual: ${averageFramesData[marker.bhomeId]?.average ?? 'data not available'}`,
    };
};

import { createAsyncAction } from '@beewise/utils';

export const FETCH_RANCHES = createAsyncAction('FETCH_RANCHES');

export const CREATE_RANCH_DRAFT = createAsyncAction('CREATE_RANCH_DRAFT');

export const UPDATE_RANCH = createAsyncAction('UPDATE_RANCH');

export const UPDATE_RANCH_MAIN = createAsyncAction('UPDATE_RANCH_MAIN');

export const FETCH_BROKERS = createAsyncAction('FETCH_BROKERS');

export const SEND_SMS_WITH_COORDINATES = createAsyncAction('SEND_SMS_WITH_COORDINATES');

export const REQUEST_RANCH_APPROVAL = createAsyncAction('REQUEST_RANCH_APPROVAL');

export const DELETE_RANCHES_NEW_FLOW = createAsyncAction('DELETE_RANCHES_NEW_FLOW');

export const FETCH_COMPANIES_WITH_USERS = createAsyncAction('FETCH_COMPANIES_WITH_USERS');

export const FETCH_RANCH = createAsyncAction('FETCH_RANCH');

export const FETCH_BHOMES = createAsyncAction('FETCH_BHOMES');

export const FETCH_COMPANY_USERS = createAsyncAction('FETCH_COMPANY_USERS');

export const FETCH_RANCH_MANAGERS = createAsyncAction('FETCH_RANCH_MANAGERS');

export const CREATE_RANCH_MANAGER = createAsyncAction('CREATE_RANCH_MANAGER');

export const FETCH_EXISTING_COMPANY_BY_NAME = createAsyncAction('FETCH_EXISTING_COMPANY_BY_NAME');

export const DELETE_RANCH_MANAGER_BY_ID = createAsyncAction('DELETE_RANCH_MANAGER_BY_ID');

export const DELETE_COMPANY_BY_ID = createAsyncAction('DELETE_COMPANY_BY_ID');

export const FETCH_UPDATE_RANCH_STATUS = createAsyncAction('FETCH_UPDATE_RANCH_STATUS');

export const SET_SELECTED_BROKER_FILTER = 'SET_SELECTED_BROKER_FILTER';

export const SET_RANCH_SEARCH_QUERY = 'SET_RANCH_SEARCH_QUERY';

export const SET_HOVERED_RANCH_ID = 'SET_HOVERED_RANCH_ID';
export const SET_SELECTED_RANCH_ID = 'SET_SELECTED_RANCH_ID';

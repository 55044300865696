import React from 'react';
import Menu from '@beewise/menu';
import { useDispatch } from 'react-redux';
import IconButton from '@beewise/icon-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faUser } from '@fortawesome/pro-solid-svg-icons';
import { signOut } from 'components/views/SignIn/actions/sign.in.actions';

const UserMenu = () => {
    const dispatch = useDispatch();

    const handleSignOut = () => dispatch(signOut());

    return (
        <div className="main-menu">
            <Menu
                customComponent={<IconButton icon={faUser} className="user-icon" />}
                position="bottom right"
                options={[
                    {
                        label: (
                            <div className="main-menu-item">
                                <FontAwesomeIcon icon={faArrowRightFromBracket} className="logout-icon" />
                                Logout
                            </div>
                        ),
                        onClick: handleSignOut,
                    },
                ]}
            />
        </div>
    );
};

export default UserMenu;

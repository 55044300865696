import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { getBrokers, getGrowers, getRanchManagers, getCompanyById } from 'components/views/BrokerView/selectors';
import { InputFormField, SelectFormField } from '@beewise/hook-form';
import constants from 'appConstants';
import { mapToOptions } from 'utils/mapToOptions';
import DependentField from 'components/reusables/Form/DependentField';
import useInitStep1FormData from '../../../hooks/useInitStep1FormData';
import TreeVarietiesFields from './TreeVarietyField';
import Footer from '../Footer';
import './RanchForm.scss';
import 'Typography.scss';

const { CROP } = constants;

const cropOptions = Object.entries(CROP).map(([key, value]) => ({
    label: value,
    value: key,
}));

const useGetGrowerContacts = ({ value: companyId }) => {
    const contacts = useSelector(state => getCompanyById(state, companyId)?.users?.filter(user => user.isGrower) || []);
    const options = mapToOptions({ data: contacts, fields: { value: 'id', label: 'username' } });
    const preselectedValues = contacts.map(contact => contact.id);
    return { options, preselectedValues };
};

const RanchForm = ({ setIsFirstStep }) => {
    const { brokers, growers, ranchManagers } = useSelector(
        state => ({
            brokers: getBrokers(state),
            growers: getGrowers(state),
            ranchManagers: getRanchManagers(state),
        }),
        shallowEqual
    );

    const brokerOptions = mapToOptions({ data: brokers, fields: { value: 'id', label: 'username' } });
    const growersOptions = mapToOptions({ data: growers, fields: { value: 'id', label: 'name' } });
    const ranchManagersOptions = mapToOptions({ data: ranchManagers, fields: { value: 'id', label: 'username' } });

    const { form, onSubmit, onCancel } = useInitStep1FormData({ setIsFirstStep, brokerOptions });

    const { control, setValue } = form;

    return (
        <form className="ranch-form" onSubmit={onSubmit}>
            <SelectFormField name="brokerUserId" control={control} label="Brokers" required options={brokerOptions} />
            <fieldset className="row">
                <InputFormField name="name" control={control} label="Ranch name" required />
                <SelectFormField
                    name="companyId"
                    control={control}
                    label="Company"
                    options={growersOptions}
                    onChange={() => setValue('contactIds', [])}
                />
            </fieldset>
            <SelectFormField
                name="ranchManagerId"
                control={control}
                label="Ranch manager"
                options={ranchManagersOptions}
            />
            <DependentField control={control} name="companyId" useHook={useGetGrowerContacts}>
                {({ options, preselectedValues }) => (
                    <SelectFormField
                        name="contactIds"
                        control={control}
                        label="Contacts"
                        options={options}
                        isMulti
                        className="updated-select-field"
                        isCheckboxEnabled
                        value={preselectedValues}
                        isDisabled
                    />
                )}
            </DependentField>
            <fieldset className="row">
                <InputFormField name="requiredHivesAmount" control={control} label="Contracted hives" type="number" />
                <InputFormField name="totalArea" control={control} label="Acres" type="number" />
            </fieldset>
            <fieldset className="row">
                <SelectFormField name="crop" control={control} label="Crop" options={cropOptions} />
                <InputFormField name="quality" control={control} label="Hive grade" />
            </fieldset>
            <TreeVarietiesFields control={control} />
            <Footer onCancel={onCancel} />
        </form>
    );
};

RanchForm.propTypes = {
    setIsFirstStep: PropTypes.func.isRequired,
};

export default RanchForm;

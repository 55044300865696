import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@beewise/icon';

const LabelPin = ({ note }) =>
    note ? <div className="label-pin">{note}</div> : <Icon type="label" className="label-pin-icon" color="yellow" />;

LabelPin.propTypes = {
    note: PropTypes.string.isRequired,
};

export default LabelPin;

import React, { createContext, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import useList from '../hooks/useList';

const ListContext = createContext();

export const ListProvider = ({ children }) => {
    const { listRef, scrollToSelectedItem, scrollToItem } = useList();
    const data = useMemo(
        () => ({
            listRef,
            scrollToSelectedItem,
            scrollToItem,
        }),
        [listRef, scrollToSelectedItem, scrollToItem]
    );
    return <ListContext.Provider value={data}>{children}</ListContext.Provider>;
};

ListProvider.propTypes = {
    children: PropTypes.node,
};

export const useListContext = () => useContext(ListContext);

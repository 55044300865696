import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/reusables/Icon';
import { getIcon } from './utils';

const StandardPin = ({ type }) => (
    <div className={`standard-pin ${type}-type`}>
        <Icon icon={getIcon(type)} className="pin-icon" />
    </div>
);

StandardPin.propTypes = {
    type: PropTypes.string.isRequired,
};

export default StandardPin;

import React, { useEffect, useCallback, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { loading, arrayOfObjectsShallowEqual, usePermission } from '@beewise/react-utils';
import { toJpeg } from 'html-to-image';
import RanchApproval from 'components/reusables/RightPanel/RanchApproval';
import { useParams } from 'react-router-dom';
import { updateMapBounds } from 'components/reusables/Map/components/Map/utils';
import GeneralInfoWithReports from './components/GeneralInfoWithReports';

import {
    FETCH_RANCHES,
    FETCH_FRAMES_PER_HIVE,
    FETCH_BEE_ACTIVITIES_OVER_TIME,
    FETCH_HIVE_DEVELOPMENT,
    FETCH_RANCH_BY_ID,
} from './actionTypes';
import { MapProvider, useMapContext } from '../BrokerView/views/Dashboard/context/MapContext';
import { fetchRanchApproval, setRanchApproval, setSelectedRanchId, fetchRanches } from './actions';
import { getRanches, getSelectedRanch, getSelectedRanchApproval } from './selectors';
import MapContainer from '../BrokerView/views/Dashboard/components/RanchInfo/MapContainer';
import './GrowerView.scss';

const GrowerView = () => {
    const ref = useRef(null);
    const { id } = useParams();
    const isFarmManager = usePermission('isGrower', 'general');
    const isRanchManager = usePermission('isRanchManager', 'general');
    const isGrower = isFarmManager || isRanchManager;
    const dispatch = useDispatch();
    const ranches = useSelector(getRanches, arrayOfObjectsShallowEqual);
    const selectedRanch = useSelector(getSelectedRanch, shallowEqual);
    const selectedRanchApproval = useSelector(getSelectedRanchApproval, shallowEqual);
    const { mapRef } = useMapContext();

    const exportImage = useCallback(async () => {
        if (!ref.current) {
            return;
        }

        try {
            const filter = node => {
                const exclusionClasses = ['remove-from-jpeg'];
                return !exclusionClasses.some(classname => node.classList?.contains(classname));
            };
            const dataUrl = await toJpeg(ref.current, { quality: 0.9, cacheBust: true, filter });
            const link = document.createElement('a');

            link.href = dataUrl;
            link.download = 'grower-report.jpeg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error exporting image:', error);
        }
    }, [ref]);

    const handleSelectRanch = useCallback(
        ({ ranchId }) => {
            const ranch = ranches.find(r => r.id === ranchId);

            if (!ranch) {
                return;
            }

            dispatch(setSelectedRanchId(ranch.id));
            const points = ranch.polygons?.flat?.() ?? [];
            updateMapBounds(mapRef.current.map, points);
        },
        [dispatch, mapRef, ranches]
    );

    useEffect(() => {
        id && dispatch(setSelectedRanchId(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (!selectedRanch) {
            return;
        }
        dispatch(fetchRanchApproval(selectedRanch?.id));
    }, [dispatch, selectedRanch]);

    useEffect(() => {
        dispatch(fetchRanches(isGrower));
    }, [dispatch, isGrower]);

    if (!ranches.length) {
        return;
    }

    return (
        <>
            {selectedRanchApproval && !!Object.keys(selectedRanchApproval).length && (
                <RanchApproval
                    selectedRanchApproval={selectedRanchApproval}
                    className="approval-wrapper"
                    setRanchApprovalHandler={setRanchApproval}
                />
            )}
            <section className="grower-view" ref={ref}>
                <GeneralInfoWithReports
                    ranches={ranches}
                    selectedRanch={selectedRanch}
                    exportImage={exportImage}
                    isGrower={isGrower}
                    handleSelectRanch={handleSelectRanch}
                />
                <MapContainer currentRanch={selectedRanch} handleSelectRanch={handleSelectRanch} />
            </section>
        </>
    );
};

const GrowerWrapper = () => (
    <MapProvider>
        <GrowerView />
    </MapProvider>
);

export default loading([
    FETCH_RANCHES.default,
    FETCH_FRAMES_PER_HIVE.default,
    FETCH_BEE_ACTIVITIES_OVER_TIME.default,
    FETCH_HIVE_DEVELOPMENT.default,
    FETCH_RANCH_BY_ID.default,
])(GrowerWrapper);

export const defaultBounds = {
    ne: { lat: 0, lng: 180 },
    nw: { lat: 180, lng: -180 },
    se: { lat: 0, lng: 180 },
    sw: { lat: 180, lng: -180 },
};

export const updateMapBounds = (map, points) => {
    if (!points.length || !map) {
        return;
    }

    const bounds = new window.google.maps.LatLngBounds();
    points.forEach(point => bounds.extend(new window.google.maps.LatLng(point?.lat, point?.lng)));
    map.fitBounds(bounds);
    // if only 1 ranch is available  - zoom in to it
    if (points.length === 1 || map.zoom > 15) {
        map.setZoom(15);
    }
};

export const zoomInToBhomes = (item, map) => {
    if (item && map) {
        if (map.getZoom() >= 15) {
            map.panTo({ lat: item.lat, lng: item.lng });
        } else {
            map.setCenter({ lat: item.lat, lng: item.lng });
            map.setZoom(15);
        }
    }
};

// extra labels with bars, restaurants, businesses, etc., which we don't want to show
const LABEL_CONFIG = [
    {
        featureType: 'poi.business',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.business',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
];

const DEFAULT_MAP_CONFIG = {
    fullscreenControl: false,
    zoomControl: false,
    minZoom: 2,
    scaleControl: true,
    mapTypeId: 'hybrid',
    className: 'map-v2',
    restriction: {
        latLngBounds: {
            north: 85,
            south: -85,
            west: -180,
            east: 180,
        },
    },
    styles: LABEL_CONFIG,
};

export default DEFAULT_MAP_CONFIG;

import { usePermission, AnalProvider, PermissionsProvider } from '@beewise/react-utils';
import { BrowserRouter as Router, Route, Routes as DomRoutes } from 'react-router-dom';

import { TRACKING_CODE, UI_ENV } from 'config';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import DashboardV2 from 'components/views/BrokerView/views/Dashboard';
import ConfProvider from '../components/reusables/ConfProvider';
import AdminAppHeader from '../components/reusables/AdminAppHeader';
import { ROUTE_PATHS } from './constants';
import PublicRoute from '../components/reusables/PublicRoute';
import SignIn from '../components/views/SignIn';
import ChangePassword from '../components/views/PasswordSetup';
import PrivateRoute from '../components/reusables/PrivateRoute';
import GrowerView from '../components/views/GrowerView';
import RanchEdit from '../components/views/BrokerView/views/RanchEdit';
import ContactsView from '../components/views/BrokerView/views/ContactsView';
import { fetchAppSettings } from '../components/reusables/AppHeader/actions';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(DomRoutes);

const adimRoutes = [
    <Route
        path={`${ROUTE_PATHS.DASHBOARD}`}
        key={`${ROUTE_PATHS.DASHBOARD}`}
        element={<PrivateRoute component={DashboardV2} />}
    />,
    <Route
        path={ROUTE_PATHS.RANCH_EDIT}
        key={ROUTE_PATHS.RANCH_EDIT}
        element={<PrivateRoute component={RanchEdit} />}
    />,
    <Route
        path={`${ROUTE_PATHS.RANCH_EDIT}/:id`}
        key={`${ROUTE_PATHS.RANCH_EDIT}/:id`}
        element={<PrivateRoute component={RanchEdit} />}
    />,
    <Route
        path={`${ROUTE_PATHS.CONTACTS}`}
        key={`${ROUTE_PATHS.CONTACTS}`}
        element={<PrivateRoute component={ContactsView} />}
    />,
    <Route
        path={`${ROUTE_PATHS.REPORT}/:id`}
        key={`${ROUTE_PATHS.REPORT}/:id`}
        element={<PrivateRoute component={GrowerView} />}
    />,
];

const userRoutes = (
    <Route path={ROUTE_PATHS.DASHBOARD} key={ROUTE_PATHS.DASHBOARD} element={<PrivateRoute component={GrowerView} />} />
);

const Routes = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.app.user, shallowEqual);

    useEffect(() => {
        if (user?.id) {
            Sentry.setUser({ id: user.id, email: user.email });
            dispatch(fetchAppSettings());
        }
    }, [dispatch, user]);

    const isRanchManager = usePermission('isRanchManager', 'general');
    const isGrower = usePermission('isGrower', 'general');

    const isAdminPage = !isGrower && !isRanchManager;

    return (
        <Router>
            <PermissionsProvider showChildrenBeforeRefresh={false} refreshUserToken>
                <AnalProvider trackingCode={TRACKING_CODE} UI_ENV={UI_ENV}>
                    <ConfProvider>
                        <AdminAppHeader isAdminPage={isAdminPage} />
                        <section id="view-v2">
                            <SentryRoutes>
                                <Route path={ROUTE_PATHS.SIGNIN} element={<PublicRoute component={SignIn} />} />
                                <Route
                                    path={ROUTE_PATHS.SETUP_PASSWORD}
                                    element={<PublicRoute component={ChangePassword} />}
                                />
                                {isAdminPage ? adimRoutes : userRoutes}
                            </SentryRoutes>
                        </section>
                    </ConfProvider>
                </AnalProvider>
            </PermissionsProvider>
        </Router>
    );
};

export default Routes;

import React, { useState } from 'react';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Button, { COLORS, VARIANTS, SIZES } from '@beewise/button-v2';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { getBeekeepers } from 'components/views/BrokerView/selectors';
import OutsideClickHandler from 'components/reusables/OutsideClickHandler';
import BeekeeperItem from './BeekeeperItem';
import SectionHeader from '../SectionHeader';

import './Beekeepers.scss';
import { groupCompaniesByBrokers } from '../../../../utils';

const getButtonText = fieldsLength => (fieldsLength ? 'Assign another' : 'Assign beekeeper');

const Beekeepers = ({ control, getValues }) => {
    const beekeepers = useSelector(getBeekeepers, arrayOfObjectsShallowEqual);
    const beekeeperOptions = groupCompaniesByBrokers(beekeepers);

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'orders',
    });
    const [editedIndex, setEditedIndex] = useState(null);

    const validateFields = () => {
        const orders = getValues('orders');
        return orders.some(order => !order.beekeeperId || !order.hivesAmount);
    };

    const clearEmptyFields = () => {
        const orders = getValues('orders');
        const emptyFieldIndex = orders.findIndex(order => !order.beekeeperId || !order.hivesAmount);
        if (emptyFieldIndex !== -1) {
            remove(emptyFieldIndex);
        }
    };

    const handleAddBeekeeper = () => {
        if (validateFields()) return;
        setEditedIndex(fields.length);
        append({ beekeeperId: null, hivesAmount: '', isInstaller: false });
    };
    return (
        <OutsideClickHandler
            onOutsideClick={() => {
                clearEmptyFields();
                setEditedIndex(null);
            }}
            className="beekeepers"
        >
            <SectionHeader title="Beekeepers" icon="beekeeper" />
            {fields.map((field, index) => (
                <BeekeeperItem
                    key={field.id}
                    control={control}
                    remove={remove}
                    index={index}
                    isEdited={index === editedIndex}
                    options={beekeeperOptions}
                    setEditedIndex={setEditedIndex}
                />
            ))}
            <Button
                onClick={handleAddBeekeeper}
                color={COLORS.GRAY}
                variant={VARIANTS.TEXT}
                size={SIZES.SMALL}
                type="button"
            >
                + {getButtonText(fields.length)}
            </Button>
        </OutsideClickHandler>
    );
};

Beekeepers.propTypes = {
    control: PropTypes.shape({}).isRequired,
    getValues: PropTypes.func.isRequired,
};

export default Beekeepers;

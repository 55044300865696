import React, { useRef, useState } from 'react';
import TextField from '@beewise/text-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import useDebounceEffect from 'utils/hooks/useDebounceEffect';
import { useDispatch } from 'react-redux';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { setRanchSearchQuery } from 'components/views/BrokerView/actions';
import './SearchRanches.scss';

const SearchRanches = () => {
    const dispatch = useDispatch();
    const selectSearchRef = useRef(null);
    const [inputValue, setInputValue] = useState('');

    useDebounceEffect(
        () => {
            dispatch(setRanchSearchQuery(inputValue));
        },
        [inputValue],
        500
    );

    const handleClearSearch = () => {
        setInputValue('');
    };

    return (
        <div className="ranches-search">
            {/* leave for future changes */}
            {/* <SelectField
                ref={selectSearchRef}
                options={ranchOptions}
                onChange={handleRanchClick}
                onInputChange={setInputValue}
                placeholder="Search"
                info={<FontAwesomeIcon icon={faSearch} />}
                isSearchable
                size="small"
                value={inputValue}
                isClearable
            /> */}
            <TextField
                ref={selectSearchRef}
                name="Search"
                label={
                    <>
                        <FontAwesomeIcon icon={faSearch} />
                        Search
                    </>
                }
                value={inputValue}
                size="small"
                onChange={setInputValue}
            />
            {inputValue && (
                <button className="clear-icon-wrapper" onClick={handleClearSearch}>
                    <FontAwesomeIcon icon={faTimes} className="clear-icon" />
                </button>
            )}
        </div>
    );
};

export default SearchRanches;

import React from 'react';
import { SelectField } from '@beewise/select-field';
import PropTypes from 'prop-types';
import './SelectBadge.scss';

const SelectBadge = ({ name, icon, ...props }) => (
    <SelectField
        {...props}
        className="select-badge"
        placeholder={
            <span className="placeholder">
                {icon}
                {name}
            </span>
        }
        isSearchable={false}
    />
);

SelectBadge.propTypes = {
    icon: PropTypes.string,
    name: PropTypes.string,
};

export default SelectBadge;

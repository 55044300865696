import { createSelector } from 'reselect';
import { keyBy } from 'lodash-es';
import constants from 'appConstants';

export const getRanches = state => state.brokerView.ranches;
export const getBrokers = state => state.brokerView.brokers;
export const getCompanies = state => state.brokerView.companies;
export const getCurrentRanch = state => state.brokerView.selectedRanch;
export const getHoveredRanchId = state => state.brokerView.hoveredRanchId;
export const getRanchManagers = state => state.brokerView.ranchManagers;
export const getSelectedRanchId = state => state.brokerView.selectedRanchId;
export const getBhomes = state => state.brokerView.bhomes;
export const getSelectedBrokerFilter = state => state.brokerView.selectedBrokerFilter;
export const getFilteredRanches = createSelector(
    [getRanches, state => state.brokerView.searchQuery],
    (ranches, query) => {
        const lowerCaseQuery = query.toLowerCase();
        const matchesQuery = text => text?.toLowerCase().includes(lowerCaseQuery);

        return ranches.filter(ranch => {
            const { name, companyName, orders, installersInfo } = ranch;

            return (
                matchesQuery(name) ||
                matchesQuery(companyName) ||
                orders.some(order => matchesQuery(order.beekeeperName)) ||
                installersInfo.some(installer => matchesQuery(installer.name))
            );
        });
    }
);

export const getGrowers = createSelector(getCompanies, companies =>
    companies.filter(company => company.type.includes(constants.COMPANY_TYPES.GROWER))
);
export const getBeekeepers = createSelector(
    [getCompanies, (state, selectedBrokerFilter) => selectedBrokerFilter],
    (companies, selectedBrokerFilter) =>
        companies
            .filter(company => company.type.includes(constants.COMPANY_TYPES.BEEKEEPER))
            .filter(company =>
                selectedBrokerFilter
                    ? company.brokers.some(broker => broker.id === selectedBrokerFilter) ||
                      company.country === selectedBrokerFilter
                    : true
            )
);
export const getInstallers = createSelector(getCompanies, companies =>
    companies.filter(company => company.type.includes(constants.COMPANY_TYPES.INSTALLER))
);

export const getCompanyById = createSelector(
    getCompanies,
    (_, companyId) => companyId,
    (companies, companyId) => companyId && companies.find(company => company.id === companyId)
);

export const ranchManagerById = createSelector(
    getRanchManagers,
    (_, managerId) => managerId,
    (ranchManagers, managerId) => managerId && ranchManagers.find(manager => manager.id === managerId)
);

export const getIsRanchHovered = createSelector(
    getHoveredRanchId,
    (state, ranchId) => ranchId,
    (hoveredRanchId, ranchId) => hoveredRanchId === ranchId
);

export const getIsRanchSelected = createSelector(
    getSelectedRanchId,
    (state, ranchId) => ranchId,
    (selectedRanchId, ranchId) => selectedRanchId === ranchId
);

export const getSelectedRanch = createSelector(
    getRanches,
    getSelectedRanchId,
    (ranches, ranchId) => ranchId && ranches.find(ranch => ranch.id === ranchId)
);

export const getBeekeeperByIds = createSelector(getBeekeepers, beekeepers => keyBy(beekeepers, 'id'));

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedRanchId } from 'components/views/BrokerView/actions';
import { updateMapBounds } from 'components/reusables/Map/components/Map/utils';
import { getRanches } from 'components/views/BrokerView/selectors';

import { useListContext } from '../context/ListContext';
import { useMapContext } from '../context/MapContext';

const useRanchSelection = () => {
    const dispatch = useDispatch();
    const ranches = useSelector(getRanches);
    const { scrollToItem } = useListContext();
    const { mapRef, handleSelectPolygon, setSelectedRanchRef } = useMapContext();

    const handleSelectRanch = useCallback(
        ({ ranchId, shouldScroll = true }) => {
            const ranchIndex = ranches.findIndex(r => r.id === ranchId);
            const ranch = ranches[ranchIndex];
            dispatch(setSelectedRanchId(ranchId));
            setSelectedRanchRef(ranchId);
            if (shouldScroll && ranchIndex !== -1) {
                scrollToItem({ scrollToIndex: ranchIndex });
            }
            if (ranch.polygons?.length) {
                updateMapBounds(mapRef.current.map, ranch.polygons?.flat());
                handleSelectPolygon({ ranchId: ranch.id });
            }
        },
        [dispatch, mapRef, handleSelectPolygon, ranches, scrollToItem, setSelectedRanchRef]
    );

    return { handleSelectRanch };
};

export default useRanchSelection;

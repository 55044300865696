import { lazy } from 'react';

export const MODALS = {
    CREATE_LOCATION: 'CREATE_LOCATION',
    REMOVE_CONFIRMATION: 'REMOVE_CONFIRMATION',
    AUTO_PLACE: 'AUTO_PLACE',
    UNIVERSAL_TEXT_MODAL: 'UNIVERSAL_TEXT_MODAL',
    SEND_RANCH_LOCATION: 'SEND_RANCH_LOCATION',
    PIN_MODAL_deprecated: 'PIN_MODAL_deprecated',
    PIN_MODAL: 'PIN_MODAL',
    LOCATION_DETAILS: 'LOCATION_DETAILS',
    DELETE_RANCH: 'DELETE_RANCH',
    UNVERIFIED_PLACEMENT: 'UNVERIFIED_PLACEMENT',
};

export const modalsConfig = {
    [MODALS.LOCATION_DETAILS]: lazy(() => import('./MapEntityModals/LocationModals/LocationDetails')),
    [MODALS.CREATE_LOCATION]: lazy(() => import('./CreateLocationModal')),
    [MODALS.REMOVE_CONFIRMATION]: lazy(() => import('./RemoveConfirmationModal')),
    [MODALS.AUTO_PLACE]: lazy(() => import('./AutoPlaceModal')),
    [MODALS.UNIVERSAL_TEXT_MODAL]: lazy(() => import('./UniversalTextModal')),
    [MODALS.SEND_RANCH_LOCATION]: lazy(() => import('./SendRanchLocation')),
    [MODALS.PIN_MODAL]: lazy(() => import('./MapEntityModals/PinModal')),
    [MODALS.DELETE_RANCH]: lazy(() => import('./DeleteRanch')),
    [MODALS.UNVERIFIED_PLACEMENT]: lazy(() => import('./MapEntityModals/LocationModals/UnverifiedPlacement')),
};

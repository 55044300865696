import React from 'react';
import PropTypes from 'prop-types';
import './StatusOptionName.scss';

const StatusOptionName = ({ name, title }) => (
    <div className="status-option">
        <div className="status-option-name">{name}</div>
        <div className="status-option-title">{title}</div>
    </div>
);

StatusOptionName.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
};

export default StatusOptionName;

export const ROUTE_PATHS = {
    DASHBOARD: '/',
    SIGNIN: '/signin',
    SETUP_PASSWORD: '/setup-password',
    CONFERENCE: '/conf',
    REPORT: '/report',
    RANCH_EDIT: '/ranch',
    COMPANIES: '/companies',
    CONTACTS: '/contacts',
};

import { sortBy } from 'lodash-es';
import {
    FETCH_RANCHES,
    FETCH_USER,
    FETCH_FRAMES_PER_HIVE,
    FETCH_RANCH_APPROVAL,
    SET_RANCH_APPROVAL,
    FETCH_BEE_ACTIVITIES_OVER_TIME,
    FETCH_HIVE_DEVELOPMENT,
    FETCH_RANCH_BY_ID,
    SET_SELECTED_RANCH_ID,
} from '../actionTypes';

const grower = (
    state = {
        ranches: [],
        content: {},
        ranchBroker: null,
        framesPerHiveByBhomeId: null,
        selectedRanchApproval: null,
        beeActivitiesOverTime: [],
        selectedRanchId: null,
    },
    action
) => {
    switch (action.type) {
        case FETCH_RANCHES.success:
            const sortedRanches = sortBy(action.data.ranches, [
                o => o?.locations.filter(location => location.bhomeIds?.length).length,
            ]);
            return {
                ...state,
                ranches: sortedRanches,
                selectedRanchId: state.selectedRanchId || sortedRanches[0]?.id,
            };
        case FETCH_USER.success:
            return {
                ...state,
                ranchBroker: action.data,
            };
        case FETCH_FRAMES_PER_HIVE.success:
            return {
                ...state,
                framesPerHiveByBhomeId: action.data,
            };
        case FETCH_RANCH_BY_ID.success:
            return {
                ...state,
                selectedRanchId: action.data?.id,
            };
        case FETCH_RANCH_APPROVAL.success:
        case SET_RANCH_APPROVAL.success:
            return {
                ...state,
                selectedRanchApproval: action.data || { ...state.selectedRanchApproval },
            };
        case FETCH_BEE_ACTIVITIES_OVER_TIME.success:
            return {
                ...state,
                beeActivitiesOverTime: action.data,
            };

        case FETCH_HIVE_DEVELOPMENT.success:
            return {
                ...state,
                content: action.data || { ...state.content },
            };
        case SET_SELECTED_RANCH_ID:
            return {
                ...state,
                selectedRanchId: action.item,
            };
        default:
            return state;
    }
};

export default grower;

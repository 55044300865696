import { DEFAULT_NUMBER_FORMAT_UNITS } from 'components/constants';

export const formatNumberWithUnits = (num, units = DEFAULT_NUMBER_FORMAT_UNITS) => {
    // Check if the number is greater than or equal to 1 billion
    if (num >= 1e9) {
        // Format the number in billions and append the 'B' unit
        return Math.floor(num / 1e9) + units[2];
    } else if (num >= 1e6) {
        // Check if the number is greater than or equal to 1 million
        // Format the number in millions and append the 'M' unit
        return Math.floor(num / 1e6) + units[1];
    } else if (num >= 1e3) {
        // Check if the number is greater than or equal to 1 thousand
        // Format the number in thousands and append the 'K' unit
        return Math.floor(num / 1e3) + units[0];
    } else {
        // If the number is less than 1 thousand, return it as a string
        return num.toString();
    }
};

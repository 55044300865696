export const mapToOptions = ({ data, fields: { value, label, data: dataField } }) =>
    data.map(item => {
        const option = { label: item[label], value: item[value] };
        if (dataField) {
            option.data = item[dataField];
        }
        return option;
    });

export const getDefaultValuesStep2 = seletedRanch => ({
    locations:
        seletedRanch?.locations?.map(location => ({
            ...location,
            bhomes: location.bhomes?.length ? location.bhomes : [],
            standardHives: location.standardHives.length ? location.standardHives : [],
        })) ?? [],
    installers: seletedRanch?.installersInfo?.map(({ id }) => id) ?? [],
    orders: seletedRanch?.orders.map(item => ({ ...item })) ?? [],
    polygons: seletedRanch?.polygons ?? [],
    accessRoadCoordinates: seletedRanch?.accessRoadCoordinates,
    loadingZoneCoordinates: seletedRanch?.loadingZoneCoordinates,
    pins: seletedRanch?.pins ?? [],
    unverifiedPlacements: seletedRanch?.unverifiedPlacements ?? [],
});

export const groupCompaniesByBrokers = companies => {
    const groupedCompanies = {};

    companies.forEach(company => {
        company.brokers.forEach(broker => {
            if (!groupedCompanies[broker.id]) {
                groupedCompanies[broker.id] = { brokerName: broker.username, options: [] };
            }
            groupedCompanies[broker.id].options.push({
                label: company.name,
                value: company.id,
                data: company.data,
            });
        });
    });

    return Object.values(groupedCompanies).map(({ brokerName, options }) => ({
        label: brokerName,
        options,
    }));
};

import { COUNTRY_US } from 'utils/isIsraeliRanch';
import {
    FETCH_BROKERS,
    CREATE_RANCH_DRAFT,
    FETCH_RANCH_MANAGERS,
    FETCH_COMPANIES_WITH_USERS,
    FETCH_RANCHES,
    FETCH_RANCH,
    FETCH_BHOMES,
    UPDATE_RANCH_MAIN,
    UPDATE_RANCH,
    DELETE_RANCHES_NEW_FLOW,
    SET_SELECTED_BROKER_FILTER,
    SET_RANCH_SEARCH_QUERY,
    SET_HOVERED_RANCH_ID,
    SET_SELECTED_RANCH_ID,
} from '../actionTypes';
import { populateRanchesDataModel, populateRanchDataModel } from '../utils';

const initialState = {
    companies: [],
    ranchManagers: [],
    ranches: [],
    brokers: [],
    selectedRanch: null,
    bhomes: [],
    selectedBrokerFilter: COUNTRY_US,
    searchQuery: '',
    hoveredRanchId: null,
    selectedRanchId: null,
};

const brokerView = (state = initialState, action) => {
    const { type, query, data, item } = action;

    switch (type) {
        // TODO change to new api and structure once ready
        case FETCH_BROKERS.success:
            return {
                ...state,
                brokers: data,
            };
        case CREATE_RANCH_DRAFT.success:
            return {
                ...state,
                ranches: [...state.ranches, data],
                selectedRanchId: data.id,
            };
        case UPDATE_RANCH_MAIN.success:
        case UPDATE_RANCH.success:
            return {
                ...state,
                ranches: state.ranches.map(ranch =>
                    ranch.id === data.id ? populateRanchDataModel({ ...ranch, ...data }) : ranch
                ),
            };
        case FETCH_RANCH_MANAGERS.success:
            return {
                ...state,
                ranchManagers: data,
            };
        case FETCH_COMPANIES_WITH_USERS.success:
            return {
                ...state,
                companies: data,
            };
        case FETCH_RANCHES.success:
            const isSelectedRanchExists = data.ranches.some(r => r.id === state.selectedRanchId);
            return {
                ...state,
                ranches: populateRanchesDataModel(data.ranches),
                selectedRanchId: isSelectedRanchExists ? state.selectedRanchId : null,
            };
        case FETCH_RANCH.success:
            return {
                ...state,
                ranches: [populateRanchDataModel(data)],
                selectedRanchId: data.id,
            };
        case FETCH_BHOMES.success:
            return {
                ...state,
                bhomes: data.bhomes,
            };
        case DELETE_RANCHES_NEW_FLOW.success:
            return {
                ...state,
                ranches: state.ranches.filter(({ id }) => data.id !== id),
                selectedRanch: null,
            };
        case SET_SELECTED_BROKER_FILTER:
            return {
                ...state,
                selectedBrokerFilter: item,
            };
        case SET_RANCH_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: query,
            };
        case SET_HOVERED_RANCH_ID:
            return {
                ...state,
                hoveredRanchId: item,
            };
        case SET_SELECTED_RANCH_ID:
            return {
                ...state,
                selectedRanchId: item,
            };
        default:
            return state;
    }
};

export default brokerView;

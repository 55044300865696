import React, { useCallback, useRef, createContext, useMemo, useContext } from 'react';
import { difference } from 'lodash-es';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setHoveredRanchId } from 'components/views/BrokerView/actions';
import { createPolygon } from 'components/reusables/Map/components/utils';

const MapContext = createContext();

const removeMapShape = shape => {
    window.google?.maps?.event?.clearInstanceListeners(shape);
    shape.setMap(null);
};

const POLYGON_COLORS = {
    HOVERED: '#FFE6B066',
    DEFAULT: 'transparent',
};

export const getChangedEntityIds = (currentEntities, newEntities) => {
    const currentEntitiesIds = currentEntities?.map(it => it?.id);
    const newEntitiesIds = newEntities?.map(it => it?.id);
    const entitiesToRemove = difference(currentEntitiesIds, newEntitiesIds);
    const entitiesToAdd = difference(newEntitiesIds, currentEntitiesIds);
    return { idsRemove: entitiesToRemove, idsAdd: entitiesToAdd };
};

export const MapProvider = ({ children, isBrokerView = false }) => {
    const dispatch = useDispatch();
    const selectedRanchRef = useRef(null);
    const mapRef = useRef({ map: null, polygons: [] });

    const findPolygonById = useCallback(ranchId => mapRef.current.polygons.find(polygon => polygon.id === ranchId), []);

    const setSelectedRanchRef = useCallback(ranchId => {
        selectedRanchRef.current = ranchId;
    }, []);

    const hoverStrategies = useMemo(
        () => ({
            byPolygon: polygon => ({
                polygon,
                ranchId: polygon.id,
            }),
            byRanchId: ranchId => ({
                polygon: findPolygonById(ranchId),
                ranchId,
            }),
        }),
        [findPolygonById]
    );

    const handleRanchHover = useCallback(
        ({ ranchId, polygon }) => {
            const target = polygon ? hoverStrategies.byPolygon(polygon) : hoverStrategies.byRanchId(ranchId);
            if (target.ranchId === selectedRanchRef.current) {
                return;
            }
            dispatch(setHoveredRanchId(target.ranchId));
            target?.polygon?.setOptions({ fillColor: POLYGON_COLORS.HOVERED });
        },
        [dispatch, hoverStrategies]
    );

    const handleRanchHoverEnd = useCallback(
        ({ ranchId, polygon }) => {
            const target = polygon ? hoverStrategies.byPolygon(polygon) : hoverStrategies.byRanchId(ranchId);
            if (target.ranchId === selectedRanchRef.current) {
                return;
            }
            target?.polygon?.setOptions({ fillColor: POLYGON_COLORS.DEFAULT });
            dispatch(setHoveredRanchId(null));
        },
        [dispatch, hoverStrategies]
    );

    const handleSelectPolygon = useCallback(({ ranchId: id }) => {
        selectedRanchRef.current = id;
        const prevSelectedPolygon = mapRef.current.polygons.find(polygon => polygon.isSelected);
        const polygon = mapRef.current.polygons.find(polygon => polygon.id === id);
        if (prevSelectedPolygon) {
            prevSelectedPolygon.setOptions({ fillColor: POLYGON_COLORS.DEFAULT });
            prevSelectedPolygon.isSelected = false;
        }
        if (polygon) {
            polygon.setOptions({ fillColor: POLYGON_COLORS.HOVERED });
            polygon.isSelected = true;
        }
    }, []);

    const createAndAddShape = useCallback(
        ({ ranch, handleSelectCurrentItem }) => {
            const isSelected = ranch.id === selectedRanchRef.current;
            const color = isSelected ? POLYGON_COLORS.HOVERED : POLYGON_COLORS.DEFAULT;

            const polygon = createPolygon({
                map: mapRef.current.map,
                polygon: ranch.polygons,
                fillColor: color,
            });
            polygon.id = ranch.id;
            polygon.addListener('click', () => {
                handleSelectCurrentItem({ ranchId: ranch.id });
            });
            if (isBrokerView) {
                polygon.addListener('mouseover', () => {
                    handleRanchHover({ polygon });
                });
                polygon.addListener('mouseout', () => {
                    handleRanchHoverEnd({ polygon });
                });
            }
            return polygon;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, isBrokerView, handleRanchHover, handleRanchHoverEnd]
    );

    const createShapesForItems = useCallback(
        ({ ranches, handleSelectCurrentItem }) => {
            const { idsRemove, idsAdd } = getChangedEntityIds(mapRef.current.polygons, ranches);
            ranches.forEach(ranch => {
                if (idsAdd.includes(ranch.id)) {
                    const polygon = createAndAddShape({ ranch, handleSelectCurrentItem });
                    polygon.id = ranch.id;
                    mapRef.current.polygons.push(polygon);
                }
                if (idsRemove.includes(ranch.id)) {
                    const polyIndex = mapRef.current.polygons.findIndex(polygon => polygon.id === ranch.id);
                    if (polyIndex === -1) {
                        return;
                    }
                    removeMapShape(mapRef.current.polygons[polyIndex]);
                    mapRef.current.polygons.splice(polyIndex, 1);
                }
            });
        },
        [createAndAddShape]
    );

    const data = useMemo(
        () => ({
            mapRef,
            handleRanchHover,
            handleRanchHoverEnd,
            handleSelectPolygon,
            createAndAddShape,
            createShapesForItems,
            setSelectedRanchRef,
        }),
        [
            mapRef,
            handleRanchHover,
            handleRanchHoverEnd,
            handleSelectPolygon,
            createAndAddShape,
            createShapesForItems,
            setSelectedRanchRef,
        ]
    );

    return <MapContext.Provider value={data}>{children}</MapContext.Provider>;
};

MapProvider.propTypes = {
    children: PropTypes.node,
    isBrokerView: PropTypes.bool,
};

export const useMapContext = () => useContext(MapContext);

import { isEmpty } from 'lodash-es';
import { auth, PERMISSIONS_ACTION_TYPES } from '@beewise/react-utils';
import { FETCH_SIGNIN, SIGN_OUT } from 'components/views/SignIn/actionTypes';
import { FETCH_APP_SETTINGS } from 'components/reusables/AppHeader/actionTypes';
import { identifyFullStoryUser } from 'utils/fullStory';

auth.init({
    USER: 'beewise_grower_user',
    JWT_TOKEN: 'beewise_grower_jwt_token',
});

const parsedUser = auth.getUser();

const initialState = {
    user: parsedUser || {},
    isLoggedIn: !isEmpty(parsedUser),
    permission: {},
    appSettings: {},
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SIGNIN.success:
            const { token } = action.data;
            auth.setJwtToken(token);
            const user = auth.getUser();
            identifyFullStoryUser(user);

            return {
                ...state,
                user,
                isLoggedIn: true,
            };
        case SIGN_OUT: {
            auth.clearUser();
            return {
                ...state,
                user: {},
                isLoggedIn: false,
                currentBhome: null,
            };
        }
        case FETCH_APP_SETTINGS.success: {
            return {
                ...state,
                appSettings: action?.data?.settings?.config,
            };
        }
        case PERMISSIONS_ACTION_TYPES.FETCH_PERMISSION.success: {
            return {
                ...state,
                permission: action.data.permission,
                isPermissionFetched: true,
            };
        }
        default:
            return state;
    }
};

export default app;

import { COUNTRY_IL, COUNTRY_US } from 'utils/isIsraeliRanch';

export const getLoadingZoneCoordinates = ({ ranch }) => {
    if (ranch.loadingZoneCoordinates) {
        return `lng: ${ranch.loadingZoneCoordinates.lng}, lat: ${ranch.loadingZoneCoordinates.lat}`;
    }
    if (!ranch.polygons?.length) {
        return null;
    }
    return `lng: ${ranch.polygons[0][0].lng}, lat: ${ranch.polygons[0][0].lat}`;
};

export const groupBrokersByCountry = brokers => {
    const usBrokers = brokers
        .filter(broker => broker.country === COUNTRY_US)
        .map(broker => ({ label: broker.username, value: broker.id }));

    const ilBrokers = brokers
        .filter(broker => broker.country === COUNTRY_IL)
        .map(broker => ({ label: broker.username, value: broker.id }));

    return [
        {
            label: 'US Brokers',
            options: [{ label: 'All US Brokers', value: COUNTRY_US }, ...usBrokers],
        },
        {
            label: 'IL Brokers',
            options: [{ label: 'All IL Brokers', value: COUNTRY_IL }, ...ilBrokers],
        },
    ];
};

export const smoothScroll = (start, end, duration, listRef) => {
    const diff = end - start;
    let startTime;

    const scroll = timestamp => {
        if (!listRef.current) return;
        if (!startTime) startTime = timestamp;
        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1);

        listRef.current?.scrollTo(start + diff * progress);

        if (progress < 1) {
            requestAnimationFrame(scroll);
        }
    };

    requestAnimationFrame(scroll);
};

export const RANCH_STATUSES = {
    draft: 'draft',
    active: 'active',
    inactive: 'inactive',
};

export const STATUSES = {
    draft: {
        name: 'Draft',
        title: 'The ranch is viewed only by brokers.',
    },
    active: {
        name: 'Active',
        title: 'The ranch is made accessible to grower contacts, ranch managers, and installers. An invite is sent.',
    },
    inactive: {
        name: 'Inactive',
        title: 'For no longer active accounts. Ranch is not accessible to growers, ranch managers, or installers.',
    },
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@beewise/button-v2';
import { ROUTE_PATHS } from 'router/constants';
import { useDispatch } from 'react-redux';
import { setSelectedRanchId } from 'components/views/BrokerView/actions';
import './AddRanch.scss';

const AddRanch = ({ ranches }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleNewRanchClick = () => {
        navigate(ROUTE_PATHS.RANCH_EDIT);
        dispatch(setSelectedRanchId(null));
    };

    return (
        <div className="add-ranch-block">
            <h3 className="add-ranch-text">Ranches ({ranches.length})</h3>
            <Button onClick={handleNewRanchClick} startIcon="plus" color="orange">
                New Ranch
            </Button>
        </div>
    );
};

export default AddRanch;

AddRanch.propTypes = {
    ranches: PropTypes.arrayOf(PropTypes.shape()),
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { InputFormField, VALIDATIONS } from '@beewise/hook-form';
import Button, { COLORS, VARIANTS } from '@beewise/button-v2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import constants from 'appConstants';

const UserFields = ({ item, index, control, removeUser, companyType, userFields, appendUser, clearErrors }) => {
    const user = useWatch({
        control,
        name: `users[${index}]`,
    });

    const isAnyFieldFilled = !!(user && (user.username?.trim() || user.email?.trim() || user.phone?.trim()));

    useEffect(() => {
        if (isAnyFieldFilled) {
            return;
        }

        clearErrors([`users[${index}].username`, `users[${index}].email`, `users[${index}].phone`]);
    }, [isAnyFieldFilled, clearErrors, index]);

    return (
        <div key={item.id} className="user-form-section">
            <div key={item.id} className="user-header-row">
                <h3>Contact</h3>
                <button
                    type="button"
                    className="remove-user-button"
                    onClick={() => removeUser(index)}
                    aria-label="Remove User"
                >
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
            <div className="user-fields">
                <InputFormField
                    name={`users[${index}].username`}
                    control={control}
                    label="Username"
                    required={!!isAnyFieldFilled}
                    className="form-field"
                />
                <InputFormField
                    name={`users[${index}].email`}
                    pattern={VALIDATIONS.EMAIL}
                    control={control}
                    label="Email"
                    required={!!isAnyFieldFilled}
                    className="form-field"
                />
                <InputFormField
                    name={`users[${index}].phone`}
                    control={control}
                    label="Phone Number"
                    required={!!isAnyFieldFilled}
                    placeholder="+1234567890"
                    pattern={{
                        value: /^\+\d{7,15}$/,
                        message: 'Phone number must be in the format +1234567890',
                    }}
                    className="form-field"
                />
            </div>
            {companyType !== constants.ROLES.RANCH_MANAGER && userFields.length - 1 === index && (
                <div className="buttons-row">
                    <Button
                        color={COLORS.BLUE}
                        variant={VARIANTS.TEXT}
                        onClick={() =>
                            appendUser({
                                phone: '',
                                username: '',
                                email: '',
                            })
                        }
                        className="add-user-button"
                    >
                        + Add contact
                    </Button>
                </div>
            )}
        </div>
    );
};

UserFields.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
    control: PropTypes.shape.isRequired,
    removeUser: PropTypes.func.isRequired,
    companyType: PropTypes.string.isRequired,
    userFields: PropTypes.arrayOf(PropTypes.shape).isRequired,
    appendUser: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
};

export default UserFields;

import { useRef } from 'react';
import storage from 'local-storage-fallback';
import constants from 'appConstants';
import { modalController } from 'components/reusables/ModalManager/modalController';
import { MODALS } from 'components/reusables/ModalManager/config';
import { TOOLBAR_MODES } from 'components/views/BrokerView/views/RanchEdit/components/Map/utils';
import { hivesAmountKey } from 'utils';

const clearAllListeners = ({ map, polygons }) => {
    if (!map) {
        return;
    }
    window.google.maps.event.clearListeners(map, 'click');
    polygons.forEach(polygon => window.google.maps.event.clearListeners(polygon, 'click'));
};

const addClickListener = (target, event, listener) => {
    window.google.maps.event.clearListeners(target, event);
    window.google.maps.event.addListener(target, event, listener);
};

const getHivesAmount = () => storage.getItem(hivesAmountKey) || constants.DEFAULT_HIVES_AMOUNT;

const getName = ({ name, existingItems }) => {
    const nameExists = existingItems.some(item => String(item.name) === String(name));
    if (nameExists) {
        return getName({ name: name + 1, existingItems });
    }
    return name.toString();
};

const getLocationModel = ({ lat, lng, existingItems }) => ({
    lat,
    lng,
    name: getName({ name: existingItems.length + 1, existingItems }),
    hivesAmount: getHivesAmount(),
    bhomes: [],
    standardHives: [],
    plannedBhomesAmount: 0,
    companyId: null,
    id: `${constants.NEW_LOCATION_PREFIX}${lat}${lng}`,
    note: '',
});

const getPinModel = ({ lat, lng, type }) => ({
    lat,
    lng,
    type,
    note: '',
    id: `${constants.NEW_PIN_PREFIX}${lat}${lng}`,
});

export const MAP_MARKER_TYPE = {
    PIN: 'pin',
    LOCATION: 'location',
};

const useMarkerMode = ({
    map,
    polygonsRef,
    createLocation,
    removeLocation,
    updateLocation,
    form,
    createPin,
    removePin,
    updatePin,
}) => {
    const isDraggingRef = useRef(false);

    const markerStrategies = {
        [MAP_MARKER_TYPE.PIN]: {
            collection: 'pins',
            getModel: getPinModel,
            create: createPin,
            update: updatePin,
            remove: removePin,
            modal: MODALS.PIN_MODAL,
            propsGetter: () => ({}),
            getIsCurrentMode: ({ mode, pinType }) => mode && TOOLBAR_MODES[mode.toUpperCase()]?.pinType === pinType,
        },
        [MAP_MARKER_TYPE.LOCATION]: {
            collection: 'locations',
            getModel: getLocationModel,
            create: createLocation,
            update: updateLocation,
            remove: removeLocation,
            modal: MODALS.LOCATION_DETAILS,
            propsGetter: () => ({
                orders: form.getValues('orders'),
                locations: form.getValues('locations'),
            }),
            getIsCurrentMode: ({ mode, isDraggingRef }) =>
                mode === TOOLBAR_MODES.CREATE_LOCATION.title && !isDraggingRef.current,
        },
    };

    const handleAddMarker =
        ({ type, strategy }) =>
        ({ latLng }) => {
            const lat = latLng.lat();
            const lng = latLng.lng();
            const existingItems = form.getValues(strategy.collection);
            const newItem = strategy.getModel({ lat, lng, existingItems, type });
            const itemExists = existingItems.some(item => item.id === newItem.id);

            if (itemExists) {
                return;
            }
            strategy.create(newItem);
            const extraProps = strategy.propsGetter();
            modalController.set({
                name: strategy.modal,
                props: {
                    entity: newItem,
                    index: existingItems.length,
                    remove: strategy.remove,
                    update: strategy.update,
                    ...extraProps,
                },
            });
        };

    const handleEditMarker =
        ({ entity, index, mapMarkerType, isDraggingRef }) =>
        e => {
            e.preventDefault();
            e.stopPropagation();
            const strategy = markerStrategies[mapMarkerType];

            if (isDraggingRef.current) {
                return;
            }
            modalController.set({
                name: strategy.modal,
                props: {
                    isEdit: true,
                    entity,
                    index,
                    remove: strategy.remove,
                    update: strategy.update,
                    ...strategy.propsGetter(),
                },
            });
        };

    const handleMapClick = handler => {
        clearAllListeners({ map, polygons: polygonsRef.current });
        addClickListener(map, 'click', handler);
        polygonsRef.current.forEach(polygon => addClickListener(polygon, 'click', handler));
    };

    const handleMarkerModeChange = mode => {
        switch (mode) {
            case TOOLBAR_MODES.CREATE_LOCATION.title:
                handleMapClick(handleAddMarker({ strategy: markerStrategies.location }));
                break;
            case TOOLBAR_MODES.ADD_GATE.title:
                handleMapClick(handleAddMarker({ strategy: markerStrategies.pin, type: constants.PIN_TYPES.GATE }));
                break;
            case TOOLBAR_MODES.ADD_ROAD_BLOCK.title:
                handleMapClick(
                    handleAddMarker({ strategy: markerStrategies.pin, type: constants.PIN_TYPES.ROAD_BLOCK })
                );
                break;
            case TOOLBAR_MODES.ADD_NOTE.title:
                handleMapClick(handleAddMarker({ strategy: markerStrategies.pin, type: constants.PIN_TYPES.PIN }));
                break;
            case TOOLBAR_MODES.ADD_LABEL.title:
                handleMapClick(handleAddMarker({ strategy: markerStrategies.pin, type: constants.PIN_TYPES.LABEL }));
                break;
            case TOOLBAR_MODES.MARK_LOADING_ZONE.title:
                handleMapClick(
                    handleAddMarker({ strategy: markerStrategies.pin, type: constants.PIN_TYPES.LOADING_ZONE })
                );
                break;
            default:
                break;
        }
    };

    return { handleMarkerModeChange, isDraggingRef, handleEditMarker };
};

export default useMarkerMode;
